import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "3",
      d: "m4.479 26.076 3.18-1.836a.833.833 0 0 1 .57-.098l6.257 1.172c.515.096.99-.3.987-.823l-.024-5.483c-.001-.149.038-.295.113-.424l3.158-5.406a.833.833 0 0 0-.037-.9L13.366 4.71M31.668 8.1c-9.167 4.4-4.167 10.234-2.5 11.067 3.128 1.564 7.479 1.667 7.479 1.667.013-.276.02-.554.02-.833 0-9.205-7.462-16.667-16.666-16.667-9.205 0-16.667 7.462-16.667 16.667 0 9.204 7.462 16.666 16.667 16.666.28 0 .557-.007.833-.02m7.096-.08-5.278-13.915 13.915 5.278-6.17 2.467-2.467 6.17Z"
    }, null, -1)
  ])))
}
export default { render: render }