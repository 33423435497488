import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 46 46"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "m14.043 26.3 5.657 5.657m7.637-23.476 10.182 10.182c.528.529.792.793.891 1.097.087.268.087.556 0 .824-.099.304-.363.569-.89 1.096l-1.957 1.956c-.46.462-.691.692-.96.857a2.666 2.666 0 0 1-.771.32c-.307.073-.633.073-1.286.073H27.02c-.747 0-1.12 0-1.405.145-.251.128-.455.332-.583.583-.145.285-.145.659-.145 1.405v5.528c0 .652 0 .978-.074 1.285a2.666 2.666 0 0 1-.32.77c-.164.27-.395.5-.856.962L21.68 37.52c-.528.528-.792.792-1.096.89a1.333 1.333 0 0 1-.824 0c-.305-.098-.569-.362-1.097-.89L8.481 27.337c-.528-.528-.792-.792-.891-1.096a1.334 1.334 0 0 1 0-.824c.099-.305.363-.569.89-1.097l1.957-1.956c.46-.46.691-.691.96-.856.24-.146.5-.254.771-.32.307-.073.633-.073 1.286-.073h5.527c.747 0 1.12 0 1.405-.145.251-.128.455-.332.583-.583.145-.285.145-.659.145-1.405v-5.528c0-.652 0-.978.074-1.285.065-.272.173-.533.32-.771.164-.27.395-.5.856-.961l1.956-1.956c.528-.528.792-.792 1.096-.89.268-.088.557-.088.825 0 .304.098.568.362 1.096.89Z"
    }, null, -1)
  ])))
}
export default { render: render }