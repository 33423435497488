import { createI18n } from 'vue-i18n';
import { i18nConfig } from '../i18n.config';

export const formatNumber = () => {
  try {
    const { $i18n } = useNuxtApp();
    const { n } = $i18n;
    return n;
  } catch (error) {
    const i18n = createI18n(i18nConfig);
    return i18n.global.n;
  }
};
