import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M16 2.667v2.666m0 21.334v2.666M5.333 16H2.666M8.42 8.419 6.533 6.533m17.048 1.886 1.886-1.886M8.419 23.586l-1.886 1.886m17.048-1.886 1.886 1.886M29.333 16h-2.666m-4 0a6.667 6.667 0 1 1-13.334 0 6.667 6.667 0 0 1 13.333 0Z"
    }, null, -1)
  ])))
}
export default { render: render }