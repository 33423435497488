import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 88 88"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"url(#i656034815__a)\" d=\"M42.553.845c.176-1.127 1.799-1.127 1.975 0l4.112 26.24a2 2 0 0 0 3.044 1.382l15.062-9.511c.901-.57 1.948.478 1.38 1.379l-9.512 15.062a2 2 0 0 0 1.382 3.044l26.24 4.111c1.127.177 1.127 1.8 0 1.976l-26.24 4.112a2 2 0 0 0-1.382 3.044l9.511 15.062c.57.901-.478 1.948-1.38 1.38l-15.061-9.512a2 2 0 0 0-3.044 1.382l-4.112 26.24c-.176 1.127-1.799 1.127-1.975 0l-4.112-26.24a2 2 0 0 0-3.044-1.382l-15.062 9.511c-.901.57-1.948-.478-1.38-1.38l9.512-15.061a2 2 0 0 0-1.382-3.044L.845 44.528c-1.127-.176-1.127-1.799 0-1.975l26.24-4.112a2 2 0 0 0 1.382-3.044l-9.511-15.062c-.57-.901.478-1.948 1.379-1.38l15.062 9.512a2 2 0 0 0 3.044-1.382L42.552.845Z\"></path><defs><linearGradient id=\"i656034815__a\" x1=\"-4.098\" x2=\"92.54\" y1=\"-7.501\" y2=\"92.54\" gradientUnits=\"userSpaceOnUse\"><stop offset=\".21\" stop-color=\"#877DE0\"></stop><stop offset=\".475\" stop-color=\"#5450CC\"></stop><stop offset=\".69\" stop-color=\"#CA77CC\"></stop></linearGradient></defs>", 2)
  ])))
}
export default { render: render }