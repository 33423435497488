import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "3",
      d: "M35.334 36.667c.467 0 .7 0 .878-.09a.833.833 0 0 0 .364-.365c.091-.178.091-.411.091-.878V18.001c0-.467 0-.7-.09-.879a.833.833 0 0 0-.365-.364c-.178-.09-.411-.09-.878-.09h-4c-.467 0-.7 0-.878.09a.833.833 0 0 0-.364.364c-.091.179-.091.412-.091.879v4c0 .466 0 .7-.091.878a.833.833 0 0 1-.364.364c-.179.091-.412.091-.879.091h-4c-.466 0-.7 0-.878.09a.833.833 0 0 0-.364.365c-.091.178-.091.412-.091.878v4c0 .467 0 .7-.09.879a.833.833 0 0 1-.365.364c-.178.09-.412.09-.878.09h-4c-.467 0-.7 0-.879.091a.833.833 0 0 0-.364.365c-.09.178-.09.411-.09.878v4c0 .467 0 .7.09.878.08.157.207.285.364.364.179.091.412.091.879.091h17.333ZM16.667 11.334c0-.467 0-.7.091-.878a.833.833 0 0 1 .364-.364c.179-.091.412-.091.879-.091h4c.466 0 .7 0 .878.09.157.08.284.208.364.365.091.178.091.411.091.878v4c0 .467 0 .7-.09.878a.833.833 0 0 1-.365.364c-.178.091-.412.091-.878.091h-4c-.467 0-.7 0-.879-.09a.833.833 0 0 1-.364-.365c-.09-.178-.09-.411-.09-.878v-4ZM5 21.334c0-.467 0-.7.091-.878a.833.833 0 0 1 .365-.364C5.634 20 5.867 20 6.334 20h4c.467 0 .7 0 .878.09.157.08.285.208.364.365.091.178.091.411.091.878v4c0 .467 0 .7-.09.878a.833.833 0 0 1-.365.364c-.178.091-.411.091-.878.091h-4c-.467 0-.7 0-.878-.09a.833.833 0 0 1-.365-.365c-.09-.178-.09-.411-.09-.878v-4ZM3.334 4.667c0-.466 0-.7.09-.878a.833.833 0 0 1 .365-.364c.178-.091.412-.091.878-.091h4c.467 0 .7 0 .879.09.156.08.284.208.364.365.09.178.09.412.09.878v4c0 .467 0 .7-.09.879a.833.833 0 0 1-.364.364c-.179.09-.412.09-.879.09h-4c-.466 0-.7 0-.878-.09a.833.833 0 0 1-.364-.364c-.091-.179-.091-.412-.091-.879v-4Z"
    }, null, -1)
  ])))
}
export default { render: render }