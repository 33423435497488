<script setup lang="ts">
import clsx from 'clsx';
import type { RouteLocationRaw } from 'vue-router';

export type Props = {
  icon?: SolaraIcon;
  type?: BtnType;
  size?: BtnSize;
  testId: string;
  bold?: boolean;
  disabled?: boolean;
  border?: BtnBorder;
  variant?: BtnVariant;
  rounded?: Size | '';
  width?: 'full' | 'auto' | 'max';
  loading?: boolean;
  to?: RouteLocationRaw;
};

const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void;
}>();

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  bold: true,
  to: undefined,
  width: 'auto',
  rounded: 'lg',
  type: 'button',
  loading: false,
  icon: undefined,
  disabled: false,
  border: undefined,
  variant: 'primary',
});

const variants: Record<BtnVariant, Record<BtnStates, string>> = {
  link: {
    default: clsx('text-primary hover:text-primary-900'),
    disabled: clsx('text-primary-500 cursor-not-allowed'),
    loading: clsx('text-primary-500 cursor-progress'),
  },
  primary: {
    default: clsx('bg-primary hover:bg-primary-900 text-white shadow-sm'),
    disabled: clsx('bg-primary-200 cursor-not-allowed text-white'),
    loading: clsx('bg-primary-500 cursor-progress text-white'),
  },
  secondary: {
    default: clsx(
      'border-primary text-primary hover:border-primary-900 hover:text-primary-500 bg-primary-100 shadow-sm'
    ),
    disabled: clsx(
      'border-primary-500 text-primary-500 cursor-not-allowed border bg-white'
    ),
    loading: clsx(
      'border-primary-500 text-primary-500 cursor-progress border bg-white'
    ),
  },
  default: {
    default: clsx('border bg-white text-gray-500 shadow-sm hover:bg-gray-50'),
    disabled: clsx('cursor-not-allowed border bg-white text-gray-500'),
    loading: clsx('cursor-progress border bg-white text-gray-500'),
  },
  warn: {
    default: clsx('bg-red-600 text-white hover:bg-red-800'),
    disabled: clsx('cursor-not-allowed bg-red-500 text-white'),
    loading: clsx('cursor-progress bg-red-500 text-white'),
  },
  none: {
    default: clsx(''),
    disabled: clsx(''),
    loading: clsx(''),
  },
  share: {
    default: clsx('bg-[#E1EAF7] text-[#006EFF] shadow-sm'),
    disabled: clsx('bg-[#E1EAF7] text-[#006EFF]'),
    loading: clsx('bg-[#E1EAF7] text-[#006EFF]'),
  },
};

const common = clsx(
  'focus-visible:outline-primary flex items-center justify-center space-x-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
);

const sizes = {
  icon: clsx('p-1'),
  'icon-md': clsx('p-3'),
  sm: clsx('px-2 py-1 text-sm'),
  md: clsx('text-md px-4 py-3'),
  lg: clsx('px-4 py-3 text-lg'),
};

const borders = {
  sm: clsx('border'),
  md: clsx('border border-2'),
  lg: clsx('border border-4'),
};

const widths = {
  full: clsx('w-full'),
  auto: clsx('w-auto'),
  max: clsx('w-max'),
};

const width = computed(() => widths[props.width]);
const loading = computed(() => props.loading);
const disabled = computed(() => props.disabled || loading.value);
const state = computed(() => {
  if (loading.value) return 'loading';
  if (props.disabled) return 'disabled';
  return 'default';
});

const classes = computed(() =>
  clsx(
    common,
    sizes[props.size],
    variants[props.variant][state.value],
    props.bold && 'font-semibold',
    props.border && borders[props.border],
    width.value,
    classSize('rounded', props.rounded)
  )
);

const handleClick = (event: MouseEvent) => {
  if (!props.disabled && !loading.value) {
    emit('click', event);
  }
};
</script>

<template>
  <NuxtLink
    v-if="type === 'link'"
    v-bind="$attrs"
    :test-id
    :to="disabled || loading ? undefined : to"
    :disabled="disabled || loading"
    :class="props.variant !== 'none' && classes"
    role="link"
    :aria-disabled="disabled || loading"
    @click.stop="handleClick">
    <SolaraIcon v-if="icon" :icon="icon" />
    <slot />
  </NuxtLink>
  <button
    v-else
    v-bind="$attrs"
    :test-id
    :disabled="disabled || loading"
    :class="props.variant !== 'none' && classes"
    @click.stop="handleClick">
    <SolaraIcon v-if="icon" :icon="icon" />
    <slot />
  </button>
</template>

<style>
.material-icons.loading {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
