import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "3",
      d: "M21.333 10.667V6.933c0-1.493 0-2.24-.29-2.81a2.667 2.667 0 0 0-1.166-1.166c-.57-.29-1.317-.29-2.81-.29H6.933c-1.493 0-2.24 0-2.81.29-.502.256-.91.664-1.166 1.166-.29.57-.29 1.317-.29 2.81v10.134c0 1.493 0 2.24.29 2.81.256.502.664.91 1.166 1.166.57.29 1.317.29 2.81.29h3.734M16 20l2.667 2.667 6-6m-9.734 12.666h10.134c1.493 0 2.24 0 2.81-.29.502-.256.91-.664 1.166-1.166.29-.57.29-1.317.29-2.81V14.933c0-1.493 0-2.24-.29-2.81a2.666 2.666 0 0 0-1.166-1.166c-.57-.29-1.317-.29-2.81-.29H14.933c-1.493 0-2.24 0-2.81.29-.502.256-.91.664-1.166 1.166-.29.57-.29 1.317-.29 2.81v10.134c0 1.493 0 2.24.29 2.81.256.502.664.91 1.166 1.166.57.29 1.317.29 2.81.29Z"
    }, null, -1)
  ])))
}
export default { render: render }