import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "3",
      d: "M29.167 10.833 10.833 29.167M14.167 17.5v-6.667m-3.334 3.334H17.5m5 11.666h6.667M13 35h14c2.8 0 4.2 0 5.27-.545a5 5 0 0 0 2.185-2.185C35 31.2 35 29.8 35 27V13c0-2.8 0-4.2-.545-5.27a5 5 0 0 0-2.185-2.185C31.2 5 29.8 5 27 5H13c-2.8 0-4.2 0-5.27.545A5 5 0 0 0 5.545 7.73C5 8.8 5 10.2 5 13v14c0 2.8 0 4.2.545 5.27a5 5 0 0 0 2.185 2.185C8.8 35 10.2 35 13 35Z"
    }, null, -1)
  ])))
}
export default { render: render }