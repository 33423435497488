<script setup lang="ts">
import clsx from 'clsx';
import type { DefineComponent } from 'vue';
import AlertCircle from '../assets/icons/alert-circle.svg?component';
import AlertTriangle from '../assets/icons/alert-triangle.svg?component';
import Area01 from '../assets/icons/area-01.svg?component';
import ArrowDown from '../assets/icons/arrow-down.svg?component';
import ArrowLeft from '../assets/icons/arrow-left.svg?component';
import ArrowRight from '../assets/icons/arrow-right.svg?component';
import ArrowUp from '../assets/icons/arrow-up.svg?component';
import Briefcase01 from '../assets/icons/briefcase-01.svg?component';
import Building09 from '../assets/icons/building-09.svg?component';
import BuildingOffice from '../assets/icons/building-office.svg?component';
import CalculatingSolar from '../assets/icons/calculating-solar.svg?component';
import CalculatingSystem from '../assets/icons/calculating-system.svg?component';
import CalculatingYield from '../assets/icons/calculating-yield.svg?component';
import Calendar02 from '../assets/icons/calendar-02.svg?component';
import Calendar from '../assets/icons/calendar.svg?component';
import CheckCircleBroken from '../assets/icons/check-circle-broken.svg?component';
import CheckVerified01 from '../assets/icons/check-verified-01.svg?component';
import Check from '../assets/icons/check.svg?component';
import Coins02 from '../assets/icons/coins-02.svg?component';
import CoinsHand from '../assets/icons/coins-hand.svg?component';
import Commission01 from '../assets/icons/commission-01.svg?component';
import Copy01 from '../assets/icons/copy-01.svg?component';
import Copy08 from '../assets/icons/copy-08.svg?component';
import CursorClick02 from '../assets/icons/cursor-click-02.svg?component';
import DotsHorizontal from '../assets/icons/dots-horizontal.svg?component';
import DotsVertical from '../assets/icons/dots-vertical.svg?component';
import Download01 from '../assets/icons/download-01.svg?component';
import Earth01 from '../assets/icons/earth-01.svg?component';
import EmailAt from '../assets/icons/email-at.svg?component';
import EmailEnvelope from '../assets/icons/email-envelope.svg?component';
import EyeOff from '../assets/icons/eye-off.svg?component';
import Eye from '../assets/icons/eye.svg?component';
import FileAttachment02 from '../assets/icons/file-attachment-02.svg?component';
import FileAttachment04 from '../assets/icons/file-attachment-04.svg?component';
import FileSearch02 from '../assets/icons/file-search-02.svg?component';
import GettingData from '../assets/icons/getting-data.svg?component';
import Globe04 from '../assets/icons/globe-04.svg?component';
import Graph01 from '../assets/icons/graph-01.svg?component';
import Hiring from '../assets/icons/hiring.svg?component';
import Hourglass03 from '../assets/icons/hourglass-03.svg?component';
import InfoCircle from '../assets/icons/info-circle.svg?component';
import InfoOctagon from '../assets/icons/info-octagon.svg?component';
import Lightning02 from '../assets/icons/lightning-02.svg?component';
import LinkExternal02 from '../assets/icons/link-external-02.svg?component';
import Loading02 from '../assets/icons/loading-02.svg?component';
import Location01 from '../assets/icons/location-01.svg?component';
import Location02 from '../assets/icons/location-02.svg?component';
import Lock01 from '../assets/icons/lock-01.svg?component';
import LogOut02 from '../assets/icons/log-out-02.svg?component';
import Menu01 from '../assets/icons/menu-01.svg?component';
import Minus from '../assets/icons/minus.svg?component';
import Money01 from '../assets/icons/money-01.svg?component';
import NewQuotation from '../assets/icons/new-quotation.svg?component';
import Panels from '../assets/icons/panels.svg?component';
import Person02 from '../assets/icons/person-02.svg?component';
import PieChart from '../assets/icons/pie-chart.svg?component';
import PiggyBank from '../assets/icons/piggy-bank.svg?component';
import Plus from '../assets/icons/plus.svg?component';
import Proposal01 from '../assets/icons/proposal-01.svg?component';
import RefreshCcw05 from '../assets/icons/refresh-ccw-05.svg?component';
import Save01 from '../assets/icons/save-01.svg?component';
import SearchSm from '../assets/icons/search-sm.svg?component';
import Share02 from '../assets/icons/share-02.svg?component';
import Share06 from '../assets/icons/share-06.svg?component';
import Shield01 from '../assets/icons/shield-01.svg?component';
import SolaraCarePlus from '../assets/icons/solara-care-plus.svg?component';
import Speedometer02 from '../assets/icons/speedometer-02.svg?component';
import Star06 from '../assets/icons/star-06.svg?component';
import Sun01 from '../assets/icons/sun-01.svg?component';
import Timer01 from '../assets/icons/timer-01.svg?component';
import Timer02 from '../assets/icons/timer-02.svg?component';
import UserCircle from '../assets/icons/user-circle.svg?component';
import Verified01 from '../assets/icons/verified-01.svg?component';
import WizardOnboardingImage02 from '../assets/icons/wizard-onboarding-image-02.svg?component';
import WizardOnboardingImage03 from '../assets/icons/wizard-onboarding-image-03.svg?component';
import WizardOnboardingStar from '../assets/icons/wizard-onboarding-star.svg?component';
import XClose from '../assets/icons/x-close.svg?component';

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  stroke?: Size;
  icon: SolaraIcon;
  rounded?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  stroke: 'md',
  rounded: false,
});

const size = computed(() => {
  switch (props.size) {
    case 'xs':
      return clsx('h-3.5 w-3.5');
    case 'sm':
      return clsx('h-4 w-4');
    case 'md':
      return clsx('h-6 w-6');
    case 'lg':
      return clsx('h-8 w-8');
    case 'xl':
      return clsx('h-10 w-10');
    case '2xl':
      return clsx('h-12 w-12');
    default:
      return clsx('h-6 w-6');
  }
});

// tailwind recognize clsx classes
const stroke = computed(() => {
  switch (props.stroke) {
    case 'sm':
      return clsx('stroke-sm');
    case 'md':
      return clsx('stroke-md');
    case 'lg':
      return clsx('stroke-lg');
    case 'xl':
      return clsx('stroke-xl');
    default:
      return clsx('stroke-md');
  }
});

const libraryIcon: Record<SolaraIcon, DefineComponent> = {
  arrow_left: ArrowLeft,
  arrow_right: ArrowRight,
  attach: FileAttachment04,
  attach_reverse: FileAttachment02,
  blocked: Lock01,
  calendar: Calendar,
  calculate: Lightning02,
  close: XClose,
  download: Download01,
  info: InfoCircle,
  link: LinkExternal02,
  loading: RefreshCcw05,
  logout: LogOut02,
  menu: DotsVertical,
  menu_lateral: DotsHorizontal,
  open_details: FileSearch02,
  proposal: Briefcase01,
  saved: CheckVerified01,
  service_number: Speedometer02,
  success: CheckCircleBroken,
  user: UserCircle,
  warning: InfoOctagon,
  tooltip: AlertCircle,
  check: Check,
  saving: Hourglass03,
  wizard: Star06,
  header_menu: Menu01,
  manual_success: CursorClick02,
  eye: Eye,
  lock: Lock01,
  loading_spinner: Loading02,
  wizard_onboarding: WizardOnboardingStar,
  star: Star06,
  shield: Shield01,
  search: SearchSm,
  building: BuildingOffice,
  new_quotation: NewQuotation,
  commission: Commission01,
  money: Money01,
  location: Location01,
  locationPin: Location02,
  piggy_bank: PiggyBank,
  graph: Graph01,
  sun: Sun01,
  panels: Panels,
  lightning: Lightning02,
  calendar_check: Calendar02,
  pie_chart: PieChart,
  share_quotation: Share06,
  hiring: Hiring,
  arrow_up: ArrowUp,
  arrow_down: ArrowDown,
  person_details: Person02,
  email_envelope: EmailEnvelope,
  earth: Earth01,
  getting_data: GettingData,
  calculating_solar: CalculatingSolar,
  calculating_system: CalculatingSystem,
  calculating_yield: CalculatingYield,
  copy: Copy01,
  copy_success: Copy08,
  native_share: Share02,
  email_at: EmailAt,
  proposal_icon: Proposal01,
  building_office: Building09,
  timer: Timer01,
  verified: Verified01,
  wizard_onboarding_2: WizardOnboardingImage02,
  wizard_onboarding_3: WizardOnboardingImage03,
  alert_triangle: AlertTriangle,
  solara_care_plus: SolaraCarePlus,
  valid_timer: Timer02,
  save_disk: Save01,
  globe: Globe04,
  area: Area01,
  hourglass: Hourglass03,
  coins: Coins02,
  coins_hand: CoinsHand,
  plus: Plus,
  minus: Minus,
  show: Eye,
  hide: EyeOff,
};
</script>

<template>
  <component
    :is="libraryIcon[props.icon]"
    :class="[rounded && 'rounded-full p-0.5', stroke, 'icon', size]" />
</template>

<style scoped>
.icon {
  vertical-align: middle;
}
</style>
