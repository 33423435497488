import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M6.4 26.4V5.6H6a1.2 1.2 0 0 1 0-2.4h20a1.2 1.2 0 1 1 0 2.4h-.4v20.8h.4a1.2 1.2 0 1 1 0 2.4h-5.6a1.2 1.2 0 0 1-1.2-1.2v-4a1.2 1.2 0 0 0-1.2-1.2h-4a1.2 1.2 0 0 0-1.2 1.2v4a1.2 1.2 0 0 1-1.2 1.2H6a1.2 1.2 0 0 1 0-2.4h.4Zm4.8-17.6A.8.8 0 0 1 12 8h1.6a.8.8 0 0 1 .8.8v1.6a.8.8 0 0 1-.8.8H12a.8.8 0 0 1-.8-.8V8.8Zm.8 5.6a.8.8 0 0 0-.8.8v1.6a.8.8 0 0 0 .8.8h1.6a.8.8 0 0 0 .8-.8v-1.6a.8.8 0 0 0-.8-.8H12Zm5.6-5.6a.8.8 0 0 1 .8-.8H20a.8.8 0 0 1 .8.8v1.6a.8.8 0 0 1-.8.8h-1.6a.8.8 0 0 1-.8-.8V8.8Zm.8 5.6a.8.8 0 0 0-.8.8v1.6a.8.8 0 0 0 .8.8H20a.8.8 0 0 0 .8-.8v-1.6a.8.8 0 0 0-.8-.8h-1.6Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }