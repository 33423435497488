import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g clip-path=\"url(#i-89216154__a)\"><path fill=\"url(#i-89216154__b)\" d=\"M14.938 9.062C20.145 9.292 24 10.185 24 12c0 1.815-3.855 2.708-9.062 2.938C14.708 20.145 13.815 24 12 24c-1.815 0-2.708-3.855-2.938-9.062C3.855 14.708 0 13.815 0 12c0-1.815 3.855-2.708 9.062-2.938C9.292 3.855 10.185 0 12 0c1.815 0 2.708 3.855 2.938 9.062Z\"></path></g><defs><linearGradient id=\"i-89216154__b\" x1=\"-.483\" x2=\"24.436\" y1=\"11.174\" y2=\"11.174\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FF9100\"></stop><stop offset=\"1\" stop-color=\"#F0B\"></stop></linearGradient><clipPath id=\"i-89216154__a\"><path fill=\"#fff\" d=\"M0 0h24v24H0z\"></path></clipPath></defs>", 2)
  ])))
}
export default { render: render }