import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, [
      _createElementVNode("path", { d: "M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" }),
      _createElementVNode("path", { d: "M12 22c4-4 8-7.582 8-12a8 8 0 1 0-16 0c0 4.418 4 8 8 12Z" })
    ], -1)
  ])))
}
export default { render: render }