<script setup lang="ts">
import clsx from 'clsx';
import DOMPurify from 'dompurify';

type Props = {
  label?: string;
  color?: 'primary' | 'warn';
  containerClass?: string;
};

withDefaults(defineProps<Props>(), {
  label: undefined,
  color: 'primary',
  containerClass: '',
});

const colorCls = {
  primary: clsx('text-primary'),
  warn: clsx('text-error'),
};
</script>

<template>
  <div
    v-if="label"
    :class="['inline-flex items-center space-x-2', containerClass]">
    <slot />
    <ClientOnly>
      <SolaraIcon
        v-tooltip="{ content: DOMPurify.sanitize(label), html: true }"
        size="xs"
        stroke="xl"
        icon="tooltip"
        :class="['cursor-help text-lg', colorCls[color]]" />
    </ClientOnly>
  </div>
  <slot v-else />
</template>

<style>
.v-popper--theme-tooltip .v-popper__wrapper > .v-popper__inner {
  @apply ring-primary max-w-sm rounded-md bg-white p-4 font-semibold text-gray-900 ring-1;
}

.v-popper__inner p {
  @apply mt-0 text-sm font-normal text-gray-600;
}

.v-popper--theme-tooltip
  .v-popper__wrapper
  > .v-popper__inner
  > div
  > div
  > *
  + p {
  @apply mt-2;
}
</style>
