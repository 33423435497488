import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M25.333 13.334H6.666m18.667 0 2.486 4.142c.557.928.835 1.393.98 1.892a4 4 0 0 1 .15 1.365c-.033.519-.204 1.033-.547 2.06l-.277.831c-.524 1.574-.787 2.36-1.273 2.942a4 4 0 0 1-1.605 1.157c-.705.277-1.535.277-3.193.277H9.946c-1.659 0-2.488 0-3.194-.277a4 4 0 0 1-1.604-1.157c-.487-.581-.749-1.368-1.274-2.942l-.277-.831c-.342-1.027-.513-1.541-.546-2.06a4 4 0 0 1 .15-1.365c.144-.5.423-.963.98-1.892l2.485-4.142m18.667 0 .557-1.948c.263-.923.395-1.384.291-1.75a1.333 1.333 0 0 0-.58-.77c-.323-.2-.803-.2-1.763-.2H8.161c-.96 0-1.44 0-1.763.2-.282.176-.49.45-.58.77-.104.366.028.827.292 1.75l.556 1.948M16 8.667h-4.714c-.695 0-1.361-.281-1.852-.781A2.691 2.691 0 0 1 8.666 6c0-.707.276-1.385.768-1.885a2.596 2.596 0 0 1 1.852-.782C14.952 3.333 16 8.668 16 8.668Zm0 0h4.714c.695 0 1.36-.281 1.852-.781.491-.5.767-1.179.767-1.886 0-.707-.276-1.385-.767-1.885a2.595 2.595 0 0 0-1.852-.782C17.047 3.333 16 8.668 16 8.668Z"
    }, null, -1)
  ])))
}
export default { render: render }