import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M8.667 26.666h-2A2.667 2.667 0 0 1 4 24V5.333a2.667 2.667 0 0 1 2.667-2.667h18.666A2.667 2.667 0 0 1 28 5.333V24a2.667 2.667 0 0 1-2.667 2.666h-2M16 25.334a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 0h.029l-4.257 4.257L8 25.819l4.026-4.026M16 25.333l4.257 4.257 3.771-3.771-4.026-4.026M12 8h8M9.333 12.666h13.334"
    }, null, -1)
  ])))
}
export default { render: render }