import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 48 48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "m18 24 4 4 9-9m4.802-9.003A4.065 4.065 0 0 0 38 12.198l3.49 1.446a4.065 4.065 0 0 1 2.2 5.312l-1.444 3.488a4.06 4.06 0 0 0 .001 3.113l1.442 3.487a4.067 4.067 0 0 1-2.2 5.313L38 35.802a4.065 4.065 0 0 0-2.201 2.197l-1.446 3.491a4.065 4.065 0 0 1-5.312 2.2l-3.487-1.445a4.065 4.065 0 0 0-3.11.003l-3.491 1.444a4.065 4.065 0 0 1-5.308-2.2l-1.447-3.49a4.065 4.065 0 0 0-2.197-2.202l-3.49-1.446a4.065 4.065 0 0 1-2.202-5.309l1.445-3.488a4.065 4.065 0 0 0-.002-3.11L4.31 18.954a4.066 4.066 0 0 1 2.2-5.314L10 12.195a4.065 4.065 0 0 0 2.2-2.195l1.445-3.49a4.065 4.065 0 0 1 5.312-2.2l3.488 1.444c.996.412 2.115.411 3.11-.002l3.492-1.44a4.065 4.065 0 0 1 5.31 2.2l1.447 3.491v-.006Z"
    }, null, -1)
  ])))
}
export default { render: render }