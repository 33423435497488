import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "3",
      d: "M21.333 6.667v18.667m-8-20v21.333M4 16.001h24M4 7.986v16.03c0 1.818 0 2.727.375 3.358.329.552.847.967 1.457 1.169.697.229 1.585.032 3.36-.363l15.467-3.437c1.192-.265 1.788-.397 2.233-.718.393-.282.7-.667.892-1.111.216-.504.216-1.115.216-2.336v-9.155c0-1.22 0-1.832-.216-2.335a2.667 2.667 0 0 0-.892-1.112c-.445-.32-1.04-.453-2.233-.718L9.192 3.821c-1.775-.394-2.663-.592-3.36-.362a2.667 2.667 0 0 0-1.457 1.169C4 5.258 4 6.168 4 7.986Z"
    }, null, -1)
  ])))
}
export default { render: render }