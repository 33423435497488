import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 30 27"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "M15 10v5.333m0 5.334h.013m-1.86-17.478L2.187 22.131c-.608 1.05-.913 1.576-.868 2.007.04.376.237.718.542.94.351.255.958.255 2.172.255h21.933c1.214 0 1.821 0 2.172-.255.306-.222.503-.564.542-.94.045-.43-.26-.956-.868-2.007L16.846 3.19c-.606-1.047-.91-1.57-1.305-1.746a1.333 1.333 0 0 0-1.083 0c-.396.176-.699.7-1.305 1.746Z"
    }, null, -1)
  ])))
}
export default { render: render }