<script setup lang="ts">
import clsx from 'clsx';
import { useField, type TypedSchema } from 'vee-validate';
import { debounce } from '../utils/debounce';

type Props = {
  id?: string;
  name?: string;
  step?: number;
  digits?: number;
  min?: number;
  max?: number;
  label?: string;
  regex?: RegExp;
  testId?: string;
  prefix?: string;
  suffix?: string;
  debug?: boolean;
  type?: InputType;
  tooltip?: string;
  checked?: boolean;
  touched?: boolean;
  loading?: boolean;
  icon?: SolaraIcon;
  helpText?: string;
  disabled?: boolean;
  showSteps?: boolean;
  parseTo?: 'boolean';
  schema?: TypedSchema;
  placeholder?: string;
  showErrors?: boolean;
  supportingText?: string;
  onErrorClasses?: string;
  containerClasses?: string;
  onDefaultClasses?: string;
  overwriteClasses?: boolean;
  value?: string | number | boolean | null;
  modelValue?: string | number | boolean | null;
  autocomplete?: 'off' | 'on' | string;
  numberType?: 'decimal' | 'currency' | 'percent';
};

const props = withDefaults(defineProps<Props>(), {
  id: '',
  name: '',
  digits: 0,
  v2: false,
  testId: '',
  min: undefined,
  max: undefined,
  step: undefined,
  type: 'text',
  helpText: '',
  checked: false,
  loading: false,
  touched: false,
  disabled: false,
  icon: undefined,
  placeholder: '',
  label: undefined,
  value: undefined,
  showErrors: true,
  regex: undefined,
  customClasses: '',
  prefix: undefined,
  suffix: undefined,
  schema: undefined,
  parseTo: undefined,
  tooltip: undefined,
  autocomplete: 'off',
  modelValue: undefined,
  numberType: 'decimal',
  overwriteClasses: false,
  supportingText: undefined,
  onDefaultClasses: undefined,
  onErrorClasses: clsx('ring-error ring-1 ring-inset'),
  containerClasses: clsx('relative flex space-x-2 rounded-md shadow-sm'),
});

let regex = props.regex;
const numberRegex = new RegExp(/[0-9.]/);
if (props.type === 'number') {
  regex = numberRegex;
}

const parser = computed(() => {
  if (props.type === 'number') {
    return (v: string) => (v === '' ? null : v);
  }

  if (props.parseTo === 'boolean') {
    return (v: string) => v === 'true';
  }

  if (props.type === 'email') {
    return (v: string) => (v === '' ? null : v.toLowerCase());
  }

  return (v: string) => v;
});

// overrride number as text
const type = props.type !== 'number' ? props.type : 'text';
const emit = defineEmits([
  'click',
  'change',
  'enter',
  'update:modelValue',
  'paste',
  'blur',
  'keyup',
]);

const updateWithDebounce = debounce(value => {
  emit('change', value);
}, 300);

if (props.type !== 'radio') {
  watch(
    () => props.modelValue,
    value => {
      if (inputValue.value !== value) {
        setTouched(true);
        setValue(value, true);
      }
    }
  );
}

const updateModel = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const value: string | number | boolean | null = parser.value(target.value);

  setTouched(true);
  setValue(value, true);
  emit('update:modelValue', value);
  updateWithDebounce(value);
};

const onBlur = (event: Event) => {
  handleBlur(event, true);

  focused.value = false;
  const target = event.target as HTMLInputElement;
  // set value as null instead of empty string
  const value = target.value || null;
  const previousValue = prevValue.value || null;
  emit('blur', {
    value,
    oldValue: previousValue,
    changed: value != previousValue,
  });
};

const onFocus = () => {
  focused.value = true;
  prevValue.value = displayValue.value;
};

const baseClasses = clsx(
  'focus-within:ring-primary block w-full rounded-md border-0 px-4 py-[9px] text-gray-900 shadow-sm placeholder:text-gray-400 focus-within:ring-2 focus-within:ring-inset sm:text-sm sm:leading-6'
);

const types: Record<InputType, string> = {
  hidden: '',
  checkbox: '',
  radio: clsx('text-primary focus-within:ring-primary h-4 w-4 border-gray-300'),
  text: baseClasses,
  number: baseClasses,
  email: baseClasses,
  password: baseClasses,
};

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');
const focused = ref(false);
const prevValue = ref();

const {
  value: inputValue,
  handleBlur,
  meta,
  errors,
  validate,
  setValue,
  setTouched,
} = useField(name, props.schema, {
  initialValue: props.value ?? props.modelValue ?? null,
  bails: false,
});

// Set first touched
if (props.touched) {
  setTouched(true);
}

const n = formatNumber();
const displayValue = computed(() => {
  if (focused.value) {
    return inputValue.value;
  }

  if (props.type === 'number') {
    if (!inputValue.value) {
      return inputValue.value;
    }

    const value = String(inputValue?.value ?? '').replace(/,/g, '');

    // % is set as suffix in the input
    return Number(value)
      ? n(Number(value), props.numberType).replace(/%/, '')
      : value;
  }

  return inputValue.value;
});

// Prevent non-regex characters from being entered
const onKeyPress = (event: KeyboardEvent): void => {
  if (!regex) {
    return;
  }

  if (!regex.test(event.key)) {
    event.preventDefault();
  }
};

const instance = getCurrentInstance();
const onPaste = (event: ClipboardEvent) => {
  // Check if the component has a custom onPaste event
  const overwriteOnPaste = !!instance?.vnode.props?.onPaste;
  const clipboardData = event.clipboardData;
  if (overwriteOnPaste) {
    event.preventDefault();
    emit('paste', clipboardData?.getData('text') ?? '');
    return;
  }

  let value = clipboardData?.getData('text') ?? '';
  if (!regex) {
    return;
  }

  event.preventDefault();
  const globalRegex = new RegExp(regex.source, 'g');
  // Remove any character that is not in the regex pattern
  value = value.match(globalRegex)?.join('') || '';

  setValue(value, true);
  emit('update:modelValue', value);
  updateWithDebounce(value);
};

const element = ref<HTMLInputElement | null>(null);
const disabled = computed(() => props.disabled);
const loading = computed(() => props.loading);

const focus = () => {
  element.value?.focus();
};

const manualSetValue = (value: string | number | boolean | null) => {
  setTouched(true);
  setValue(value, true);
  emit('update:modelValue', value);
  updateWithDebounce(value);
};

const onKeyUp = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    emit('enter', inputValue.value);
  }

  emit('keyup', event);
};

const onKeyDown = (event: KeyboardEvent) => {
  if (props.type === 'number' && ['ArrowUp', 'ArrowDown'].includes(event.key)) {
    // prevent arrow keys from moving the cursor
    event.preventDefault();

    if (event.key === 'ArrowUp') {
      handleStep('up');
    } else {
      handleStep('down');
    }
  }
};

const handleStep = (direction: 'up' | 'down') => {
  if (!props.step) {
    return;
  }

  const step = direction === 'up' ? props.step : -props.step;
  let value = Number((Number(inputValue.value) + step).toFixed(props.digits));

  if (props.min && value < props.min) {
    value = props.min;
  }

  if (props.max && value > props.max) {
    value = props.max;
  }

  setValue(value, true);
  emit('update:modelValue', value);
  updateWithDebounce(value);
};

const cursor = computed(() => {
  if (loading.value) {
    return clsx('cursor-progress');
  }

  if (disabled.value) {
    return clsx('cursor-not-allowed');
  }

  return '';
});

const background = computed(() => {
  if (disabled.value || loading.value) {
    return clsx('bg-gray-100');
  }

  return clsx('bg-[#F5F7F9]');
});

defineExpose({ validate, meta, focus, setValue: manualSetValue });
</script>

<template>
  <!-- eslint-disable vue/html-self-closing -->
  <div>
    <SolaraTooltip :label="tooltip" :color="errors.length ? 'warn' : 'primary'">
      <label
        v-if="label"
        :for="id"
        :class="[
          'ml-2 block text-balance text-xs font-semibold leading-6',
          !errors.length ? 'text-gray-900' : 'text-error',
        ]"
        >{{ label }}
        <span :class="[!errors.length ? 'text-primary' : 'text-error']">{{
          meta.required ? '*' : ''
        }}</span>
      </label>
    </SolaraTooltip>
    <div
      :class="[
        label && 'mt-1',
        !overwriteClasses ? types[type] : '',
        cursor,
        background,
        containerClasses,
        errors.length && onErrorClasses,
        !errors.length && onDefaultClasses,
      ]">
      <button
        v-if="showSteps"
        :disabled="inputValue < min"
        class="focus:ring-primary hover:ring-primary flex items-center rounded hover:ring"
        @click="handleStep('down')">
        <SolaraIcon icon="minus" />
      </button>
      <div
        v-if="$slots.prefix || prefix"
        class="prefix pointer-events-none flex items-center">
        <slot name="prefix" />
        {{ prefix }}
      </div>
      <input
        v-bind="$attrs"
        :id
        ref="element"
        :disabled="disabled || loading"
        :autocomplete
        :value="displayValue"
        :type
        :name
        :placeholder
        :test-id
        :checked
        :class="[
          cursor,
          showSteps && 'text-center',
          props.type === 'number' && 'font-medium tracking-wide',
          'w-inherit grow border-0 bg-transparent p-0 focus:shadow-none focus:outline-none focus:ring-0',
        ]"
        @blur="onBlur"
        @input="updateModel"
        @focus="onFocus"
        @paste="onPaste"
        @keypress="onKeyPress"
        @keyup="onKeyUp"
        @keydown="onKeyDown"
        @click="emit('click')" />
      <div
        v-if="$slots.suffix || suffix"
        class="pointer-events-none flex items-center text-nowrap">
        <slot name="suffix" />
        {{ suffix }}
      </div>
      <button
        v-if="showSteps"
        :disabled="inputValue > max"
        class="focus:ring-primary hover:ring-primary flex items-center rounded hover:ring"
        @click="handleStep('up')">
        <SolaraIcon icon="plus" />
      </button>
      <SolaraIcon
        v-if="icon"
        class="pointer-events-none flex items-center text-gray-400"
        :icon="icon" />
    </div>
    <section
      v-if="showErrors && errors.length && errors[0] !== ''"
      class="text-error m-2 text-xs">
      <ul>
        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
    </section>
    <section v-else-if="helpText" class="m-2 text-xs font-light">
      {{ helpText }}
    </section>
  </div>
</template>

<style scoped>
.suffix {
  direction: rtl;
}
</style>
