<script setup lang="ts">
import clsx from 'clsx';

type Props = {
  title?: string;
  icon?: SolaraIcon;
  loading?: boolean;
  description?: string;
  spinner?: SpinnerVariant;
  variant?: AlertVariant;
  closeable?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  title: '',
  loading: false,
  spinner: 'primary',
  icon: undefined,
  variant: 'default',
  closeable: false,
  description: undefined,
});

const status: Record<
  AlertVariant,
  {
    icon: string;
    container: string;
    title: string;
    text: string;
    button: string;
  }
> = {
  alert: {
    icon: clsx('text-yellow-400'),
    container: clsx('bg-yellow-50'),
    title: clsx('text-yellow-400'),
    text: clsx('text-yellow-500'),
    button: clsx(
      'focus:ring-yellow-600',
      'focus:ring-offset-yellow-50',
      'bg-yellow-50',
      'text-yellow-400',
      'hover:bg-yellow-100'
    ),
  },
  info: {
    icon: clsx('text-[#0083BD]'),
    container: clsx('bg-[#F4FAFF]'),
    title: clsx('text-[#0083BD]'),
    text: clsx('text-[#0083BD]'),
    button: clsx(
      'focus:ring-[#0083BD]',
      'focus:ring-offset-[#F4FAFF]',
      'bg-primary-100',
      'text-[#0083BD]',
      'hover:bg-primary-200'
    ),
  },
  'light-info': {
    icon: clsx('text-[#32ADE6]'),
    container: clsx('bg-white/80'),
    title: clsx('text-[#32ADE6]'),
    text: clsx('text-[#32ADE6]'),
    button: clsx(
      'focus:ring-[#32ADE6]',
      'focus:ring-offset-[#F4FAFF]',
      'bg-primary-100',
      'text-[#32ADE6]',
      'hover:bg-primary-200'
    ),
  },
  default: {
    icon: clsx('text-[#172E4D]'),
    container: clsx('bg-gray-50'),
    title: clsx('text-[#172E4D]'),
    text: clsx('text-[#172E4D]'),
    button: clsx(
      'focus:ring-gray-600',
      'focus:ring-offset-gray-50',
      'bg-gray-50',
      'text-[#172E4D]',
      'hover:bg-gray-100'
    ),
  },
  warn: {
    icon: clsx('text-warn'),
    container: clsx('bg-orange-50'),
    title: clsx('text-warn'),
    text: clsx('text-warn'),
    button: clsx(
      'focus:ring-orange-600',
      'focus:ring-offset-orange-50',
      'bg-orange-50',
      'text-warn',
      'hover:bg-orange-100'
    ),
  },
  error: {
    icon: clsx('text-error'),
    container: clsx('bg-red-50'),
    title: clsx('text-error'),
    text: clsx('text-error'),
    button: clsx(
      'focus:ring-red-600',
      'focus:ring-offset-red-50',
      'bg-red-50',
      'text-error',
      'hover:bg-red-100'
    ),
  },
  success: {
    icon: clsx('text-green-400'),
    container: clsx('bg-green-50'),
    title: clsx('text-green-400'),
    text: clsx('text-green-500'),
    button: clsx(
      'focus:ring-green-600',
      'focus:ring-offset-green-50',
      'bg-green-50',
      'text-green-400',
      'hover:bg-green-100'
    ),
  },
};

const loading = computed(() => props.loading);
const classes = computed(() => status[props.variant]);

defineEmits(['close']);
</script>

<template>
  <div :class="[`rounded-2xl p-4`, classes.container]">
    <div :class="['flex', $slots.action ? 'items-center' : 'items-start']">
      <div v-if="icon" class="mr-3 flex-shrink-0 pt-1">
        <SolaraIcon stroke="lg" :class="classes.icon" :icon />
      </div>
      <div class="w-full">
        <h3 v-if="title" :class="[`font-bold`, classes.title]">
          {{ title }}
        </h3>
        <div :class="[`text-sm`, classes.text]" v-bind="$attrs">
          <slot />
          {{ description }}
        </div>
      </div>
      <div v-if="loading" class="ml-3 flex-shrink-0 pt-1">
        <SolaraSpinner :variant="spinner" />
      </div>
      <slot name="action" />
      <div v-if="closeable" class="ml-auto pl-3 pt-1">
        <div class="-mx-1.5 -my-1.5">
          <SolaraButton
            variant="none"
            test-id="close-alert"
            :class="[
              `inline-flex rounded-md px-0 py-0 focus:outline-none focus:ring-2 focus:ring-offset-2`,
              classes.button,
            ]"
            @click="$emit('close')">
            <span class="sr-only">Dismiss</span>
            <SolaraIcon icon="close" />
          </SolaraButton>
        </div>
      </div>
    </div>
  </div>
</template>
