import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 22 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "M4 11a6 6 0 0 1 6-6m-6 6c0 1.648.665 3.142 1.74 4.226.104.104.156.157.187.207.03.048.046.09.06.144.013.057.013.123.013.253v2.37c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C6.38 19 6.52 19 6.8 19h1.9c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218c.055-.107.055-.247.055-.527v-.4c0-.28 0-.42.054-.527a.5.5 0 0 1 .219-.218C9.88 17 10.02 17 10.3 17h1.4c.28 0 .42 0 .527.055a.5.5 0 0 1 .218.218c.055.107.055.247.055.527v.4c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218c.107.055.247.055.527.055h1.9c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C16 18.62 16 18.48 16 18.2v-.976c0-.202 0-.303.029-.384a.462.462 0 0 1 .121-.19c.061-.06.166-.108.374-.205a6.025 6.025 0 0 0 2.427-2.055c.107-.155.16-.233.217-.28a.464.464 0 0 1 .17-.089c.07-.021.154-.021.32-.021h.542c.28 0 .42 0 .527-.055a.5.5 0 0 0 .218-.218C21 13.62 21 13.48 21 13.2V9.786c0-.267 0-.4-.05-.503a.5.5 0 0 0-.233-.233C20.614 9 20.481 9 20.214 9c-.193 0-.29 0-.367-.026a.463.463 0 0 1-.203-.13c-.057-.06-.103-.16-.195-.359a6.014 6.014 0 0 0-1.19-1.711c-.104-.105-.155-.157-.186-.207a.462.462 0 0 1-.06-.144C18 6.366 18 6.3 18 6.17V5.06c0-.36 0-.54-.075-.66a.5.5 0 0 0-.288-.218c-.137-.039-.31.01-.657.11l-2.372.677a.498.498 0 0 1-.136.029C14.45 5 14.43 5 14.387 5H10m-6 6H3a2 2 0 0 1-1-3.732M10 5h3.965a3.5 3.5 0 1 0-6.758.688A5.974 5.974 0 0 1 10 5Z"
    }, null, -1)
  ])))
}
export default { render: render }