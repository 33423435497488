import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M12 15.333 14.667 18l6-6m6 4c0 6.545-7.139 11.305-9.736 12.82-.296.172-.443.258-.651.303a1.514 1.514 0 0 1-.56 0c-.208-.045-.355-.131-.65-.303-2.598-1.515-9.737-6.275-9.737-12.82V9.623c0-1.066 0-1.599.175-2.057.154-.404.404-.766.729-1.052.368-.324.867-.511 1.865-.886l7.149-2.68c.277-.104.416-.156.558-.177.127-.018.255-.018.382 0 .142.02.28.073.558.177l7.15 2.68c.997.375 1.496.562 1.864.886.325.286.575.648.73 1.052.174.458.174.991.174 2.057V16Z"
    }, null, -1)
  ])))
}
export default { render: render }