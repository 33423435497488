import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M9 18.5h6M6.6 2h10.8c.56 0 .84 0 1.054.109a1 1 0 0 1 .437.437C19 2.76 19 3.04 19 3.6v2.075c0 .489 0 .733-.055.963-.05.205-.13.4-.24.579-.123.201-.296.374-.642.72L15.13 10.87c-.396.396-.594.594-.668.822a1 1 0 0 0 0 .618c.074.228.272.426.668.822l2.932 2.932c.346.346.519.519.642.72.11.18.19.374.24.578.055.23.055.475.055.965V20.4c0 .56 0 .84-.109 1.054a1 1 0 0 1-.437.437C18.24 22 17.96 22 17.4 22H6.6c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C5 21.24 5 20.96 5 20.4v-2.074c0-.49 0-.734.055-.965.05-.204.13-.399.24-.578.123-.201.296-.374.642-.72L8.87 13.13c.396-.396.594-.594.668-.822a1 1 0 0 0 0-.618c-.074-.228-.272-.426-.668-.822L5.937 7.937c-.346-.346-.519-.519-.642-.72a2 2 0 0 1-.24-.579C5 6.408 5 6.164 5 5.675V3.6c0-.56 0-.84.109-1.054a1 1 0 0 1 .437-.437C5.76 2 6.04 2 6.6 2Z"
    }, null, -1)
  ])))
}
export default { render: render }