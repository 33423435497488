import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M7 11c-.93 0-1.395 0-1.776.102a3 3 0 0 0-2.122 2.121C3 13.605 3 14.07 3 15v1.2c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C5.28 21 6.12 21 7.8 21h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C21 18.72 21 17.88 21 16.2V15c0-.93 0-1.395-.102-1.777a3 3 0 0 0-2.122-2.12C18.395 11 17.93 11 17 11m-1-4-4-4m0 0L8 7m4-4v12"
    }, null, -1)
  ])))
}
export default { render: render }