// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debounce<T extends (...args: any[]) => void>(
  fn: T,
  ms?: number | undefined
) {
  let timer: NodeJS.Timeout;
  return function (this: unknown, ...args: unknown[]) {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this; // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args); // call the function if time expires
    }, ms);
  };
}
