import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 40 40"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentcolor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "3",
      d: "M6.667 30H3.334m7.19-9.476-2.357-2.357m21.31 2.357 2.357-2.357m4.833 11.834h-3.333m-21.667 0a8.333 8.333 0 0 1 16.667 0m8.333 6.666H3.334M26.667 8.334l-6.666 6.667m0 0-6.667-6.667m6.667 6.667V3.334"
    }, null, -1)
  ])))
}
export default { render: render }